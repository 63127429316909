import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ProductComplaint } from 'app/models/product-complaint.model';
import { map } from 'rxjs/operators/map';
import { convertSnaps } from 'app/shared/helpers/db-utils';
import { getDate } from 'date-fns';
import { EmailService } from './email.service';
import { ConfigService } from './config.service';

@Injectable()
export class ProductComplaintService {
    private collectionPath = 'productComplaints';
    private productComplaintEmailTo = [
        'ruven.naidoo@dilowa.co.za',
        'mervin@jade-elephant.co.za',
        'nikita@jade-elephant.co.za',
    ];
    //      'tebogo.letsitsi@aurobindo.com',
    productComplaints$: Observable<ProductComplaint[]>;

    constructor(
        private afs: AngularFirestore,
        private configService: ConfigService,
        private emailService: EmailService
    ) {}

    getProductComplaints$(pageNumber = 0, pageSize = 100): Observable<ProductComplaint[]> {
        return this.afs
            .collection<ProductComplaint>(this.collectionPath, (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('createdDate', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    getProductComplaintsbyUid$(uid: string, pageNumber = 0, pageSize = 100): Observable<ProductComplaint[]> {
        return this.afs
            .collection<ProductComplaint>(this.collectionPath, (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('createdDate', 'asc')
                    .where('uid', '==', uid)
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    getActiveProductComplaints(): Observable<ProductComplaint[]> {
        return this.afs
            .collection(this.collectionPath)
            .snapshotChanges()
            .pipe(
                map((snaps) => {
                    let productComplaints = convertSnaps<ProductComplaint[]>(snaps).filter((q) => q.isActive === true);
                    return productComplaints;
                })
            );
    }

    saveProductComplaint(productComplaint: ProductComplaint) {
        if (productComplaint.id) {
            return this.updateProductComplaint(productComplaint);
        }
        return this.createProductComplaint(productComplaint);
    }

    createProductComplaint(productComplaint: ProductComplaint) {
        productComplaint = this.addMetaDataToProductComplaint(productComplaint);
        return this.afs
            .collection(this.collectionPath)
            .add(productComplaint)
            .then((a) => {
                productComplaint.id = a.id;
                this.sendProductComplaintEmail(productComplaint);
            });
    }

    addMetaDataToProductComplaint(productComplaint: ProductComplaint): ProductComplaint {
        productComplaint.isActive = true;
        productComplaint.createdDate = new Date();
        productComplaint.uid = this.configService.userProfile.uid;
        productComplaint.firstName = this.configService.userProfile.firstName;
        productComplaint.lastName = this.configService.userProfile.lastName;
        productComplaint.mpNumber = this.configService.userProfile.mpNumber;
        productComplaint.email = this.configService.userProfile.email;
        productComplaint.country = this.configService.userProfile.country;
        productComplaint.initials = this.configService.userProfile.initials;
        productComplaint.hcpType = this.configService.userProfile.hcpType;
        productComplaint.discipline = this.configService.userProfile.discipline;
        productComplaint.cellNumber = this.configService.userProfile.cellNumber;
        productComplaint.phoneNumber = this.configService.userProfile.phoneNumber;
        return productComplaint;
    }

    sendProductComplaintEmail(productComplaint: ProductComplaint) {
        this.emailService.sendEmail(
            this.productComplaintEmailTo,
            'New ProductComplaint:' + productComplaint.productName,
            '',
            this.buildProductComplaintEmailBody(productComplaint)
        );
    }

    buildProductComplaintEmailBody(productComplaint: ProductComplaint) {
        let html: string;

        html = ` <h1>Product Complaint: ${productComplaint.typeOfProductComplaint}</h1>
            <hr/>
            <h3> Details </h3>
            <hr/>
            <H2>Section A</h2>
                <div><b>Name of Complaint: </b><p>${productComplaint.nameOfComplainant}</p></div>
                <div><b>Contact Details of Complaint: </b><p>${productComplaint.contactDetailsOfComplainant}</p></div>
                <div><b>Address of Complaint: </b><p>${productComplaint.addressOfComplainant}</p></div>
                <div><b>Source: </b><p>${productComplaint.source}</p></div>
                <div><b>Date of Receipt: </b><p>${productComplaint.dateOfReceipt}</p></div>
                <div><b>Name of Recipient: </b><p>${productComplaint.nameOfRecipient}</p></div>
                
                <br/>
                <H2>Section B</h2>
                <div><b>Product Name: </b>${productComplaint.productName}</div>
                <div><b>Batch Number: </b>${productComplaint.batchNumber}</div>
                <div><b>Expiry Date: </b>${productComplaint.expiryDate}</div>
                <div><b>Item: </b>${productComplaint.item}</div>
                <div><b>Pack Size: </b>${productComplaint.packSize}</div>

                <div><b>Details of Complaint: </b><p>${productComplaint.detailsOfComplaint}</p></div>
        
            <hr/>
            <h3> Reported By: </h3>
                <div><b>First Name: </b>${productComplaint.firstName}</div>
                <div><b>Last Name: </b>${productComplaint.lastName}</div>
                <div><b>Initials: </b>${productComplaint.initials}</div>
                <div><b>Mp Number: </b>${productComplaint.mpNumber}</div>
                <div><b>Email: </b>${productComplaint.email}</div>
                <div><b>Country: </b>${productComplaint.country}</div>
                <div><b>HCP Type: </b>${productComplaint.hcpType}</div>
                <div><b>Discipline: </b>${productComplaint.discipline}</div>
                <div><b>Cell Number: </b>${productComplaint.cellNumber}</div>
                <div><b>Phone Number: </b>${productComplaint.phoneNumber}</div>
                <div><p style="margin:0 0 25px 0;font-size:16px;line-height:24px;font-family:Arial,sans-serif;"><b>Reference:</b> ${productComplaint.uid}</p><div>
            <hr/>
            Product Complaint Reference Code: ${productComplaint.id}
        `;
        return html;
    }

    updateProductComplaint(productComplaint: ProductComplaint) {
        return this.afs.doc(this.collectionPath + '/' + productComplaint.id).update(productComplaint);
    }

    deleteProductComplaint(id: string) {
        this.afs.doc(this.collectionPath + '/' + id).delete();
    }
}
