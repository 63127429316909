import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { MailingList } from 'app/models/mailing-list.model';

@Injectable({
    providedIn: 'root',
})
export class EmailService {
    constructor(private afs: AngularFirestore) {}

    sendEmail(to: string[], subject: string, text: string, html: string) {
        return this.afs.collection('mail').add({
            to,
            message: {
                subject: subject,
                text: text,
                html: html,
            },
        });
    }

    async sendBulkEmail(mailingList: MailingList, message, mailMessageName) {
        const emails = mailingList.emailList.split(',');
        await emails.forEach((email) => {
            this.bulkEmailSender(email, message, mailingList.id, mailMessageName);
        });
        return true;
    }

    bulkEmailSender(to: string, message, mailingListId, mailMessageName) {
        to = to.trim();
        return this.afs.collection('mail').add({
            to,
            message,
            mailMessageName,
            mailingListId,
        });
    }

    saveEmail(name: string, subject: string, html: any, mailingList: MailingList) {
        const mailingListId = mailingList.id;
        const mailingListName = mailingList.name;
        return this.afs.doc('mailMessages/' + name).set({
            name,
            subject,
            html,
            mailingListId,
            mailingListName,
        });

        // return this.afs.doc('mailingLists/' + mailingList.id).update(mailingList);
    }

    getEmail() {
        return this.afs
            .collection<any>('mailMessages', (ref) => {
                let query: Query = ref;
                query = query;
                //    .limit(pageSize)
                //    .startAfter(pageNumber * pageSize);
                return query.orderBy('name', 'asc');
            })
            .valueChanges({ idField: 'id' });
    }

    getMailLogs$(mailMessageName) {
        return this.afs
            .collection<any>('mail', (ref) => {
                let query: Query = ref;
                query = query.where('mailMessageName', '==', mailMessageName);
                //    .limit(pageSize)
                //    .startAfter(pageNumber * pageSize);
                return query;
            })
            .valueChanges({ idField: 'id' });
    }
}
